<template>
  <div class="section-collection-filter mb-2 flex">
    <div v-if="showHeader" class="mr-8 pt-2">
      <h4 class="inline">
        <font-awesome-icon class="mr-2" :icon="faSliders" />
        <b>Filters</b>
      </h4>
    </div>
    <tml-grid
      :breakpoints="{xs: gridColumnCount}"
      class="flex-auto items-start"
    >
      <template
        v-for="(filterOption, nameIndex) in filterOptions"
        :key="nameIndex"
      >
        <tml-new-select
          v-if="filterOption.filterableOptions.length > 1"
          :value-as-ref="getActiveFilterValue(nameIndex)"
          :name="`${nameIndex}_filter_select`"
          :label="startCase(nameIndex)"
          :options="selectOptions(filterOption.filterableOptions)"
          class="mb-4"
          clearable
          display-field="text"
          identifier-field="value"
          :none-selected="`All ${nameIndex}`"
          @input-as-ref="filterProducts($event, nameIndex)"
          @clear="filterProducts(null, nameIndex)"
        >
          <template v-if="showFilterIcons" #selected-content="{option}">
            <tml-color-square
              v-if="nameIndex === 'colours'"
              class="colour-square mr-2"
              :color="option.value"
              disable-tooltip
            />
            <font-awesome-icon
              v-else
              :icon="filterIcons[nameIndex]"
              class="mr-2"
            />{{ option.text }}
          </template>
          <template v-if="showFilterIcons" #none-selected-content>
            <font-awesome-icon :icon="filterIcons[nameIndex]" class="mr-2" />
            All {{ nameIndex }}
          </template>
          <template
            v-if="showColorSquares && nameIndex === 'colours'"
            #option="{option}"
          >
            <tml-color-square
              class="colour-square mr-2"
              :color="option.value"
              disable-tooltip
            />
            {{ option.value }}
          </template>
        </tml-new-select>
      </template>
      <collection-sort
        ref="sort"
        :show-icon="showFilterIcons"
        @sort="$emit('sort', $event)"
      />
    </tml-grid>
    <div v-if="isMobileView && anyFilterEnabled" class="container mt-4">
      <tml-button
        secondary
        text="Remove Filters"
        fill
        @click="clearAllFilters"
      />
    </div>

    <tml-tooltip
      v-if="loadTooltip"
      name="first-time-tooltip"
      title="Filters"
      :target="getTooltipTarget()"
      :ttl="0"
      position="top"
      disable-route-changes
    >
      Find the products you're looking for
    </tml-tooltip>
  </div>
</template>

<script>
import {faCheck, faSliders} from '@fortawesome/pro-solid-svg-icons';
import VueScrollTo from 'vue-scrollto';
import Vue from 'vue';
import {union, startCase} from 'lodash';
import {maxDevice} from '@teemill/common/helpers';
import CollectionSort from './Sort.vue';
import {
  faFillDrip,
  faShirt,
  faRulerHorizontal,
} from '@fortawesome/pro-light-svg-icons';
import Cookies from 'js-cookie';

import {overlays} from '@teemill/modules/overlays';

Vue.use(VueScrollTo);

export default {
  name: 'SectionCollectionFilter',

  components: {
    CollectionSort,
  },

  props: {
    filter: {
      required: true,
    },

    filters: Array,

    showHeader: Boolean,
    showColorSquares: Boolean,
    showFilterIcons: Boolean,
    showTooltip: Boolean,

    products: Array,
  },

  data() {
    return {
      faCheck,
      faSliders,

      productBaseColours: [],
      setFilters: {
        types: [],
        colours: [],
        sizes: [],
      },
      selectedCount: 0,

      filterIcons: {
        colours: faFillDrip,
        sizes: faRulerHorizontal,
        types: faShirt,
      },
      loadTooltip: false,
    };
  },

  computed: {
    filterOptions() {
      return this.filter.filterOptions;
    },
    filterMeta() {
      return this.filter.filterMeta;
    },
    isMobileView() {
      return maxDevice('md');
    },
    filterCounts() {
      const filter = this.filter;
      const filterOptions = filter.filterOptions;

      return {
        colours: filterOptions.colours.filterableOptions
          .filter(c => !!c.selected)
          .map(c => c.property).length,
        types: filterOptions.types.filterableOptions
          .filter(t => !!t.selected)
          .map(t => t.property).length,
        sizes: filterOptions.sizes.filterableOptions
          .filter(s => !!s.selected)
          .map(s => s.property).length,
      };
    },

    anyFilterEnabled() {
      return this.selectedCount >= 1;
    },

    filtersEnabled() {
      return this.filterMeta.enableFilters;
    },

    enabledFilters() {
      return this.filterMeta.enabledFilters;
    },

    gridColumnCount() {
      return (
        1 +
        Object.values(this.filterOptions).filter(
          options => options.filterableOptions.length > 1
        ).length
      );
    },

    tooltipCookieState() {
      return Cookies.get('collection-filters-first-time-tooltip');
    },

    hasSeenFirstTimeTooltip() {
      return this.tooltipCookieState !== undefined;
    },
  },

  watch: {
    filtersEnabled() {
      this.emitFilterSet();
    },

    filters: {
      immediate: true,
      handler(value) {
        this.setFilters = value;
      },
    },
  },

  mounted() {
    if (this.showTooltip && !this.hasSeenFirstTimeTooltip) {
      this.loadTooltip = true;
      setTimeout(() => {
        this.showFirstTimeTooltip();
      }, 2_500);
    }
  },

  methods: {
    startCase,

    incrementSelected() {
      this.selectedCount += 1;
      this.filterMeta.enableFilters = true;
    },

    decrementSelected() {
      this.selectedCount -= 1;

      if (!this.selectedCount) {
        this.filterMeta.enableFilters = false;
      }
    },

    clearAllFilters() {
      this.$refs.types[0].clear();
      if (this.$refs.colours && this.$refs.sizes) {
        this.$refs.colours[0].clear();
        this.$refs.sizes[0].clear();
      }

      this.selectedCount = 0;
      this.filterMeta.enableFilters = false;
      this.$store.dispatch('overlayElements/close', 'collection-filter');
    },
    clearSingleFilter(filterName) {
      if (this.$refs[filterName]) {
        this.$refs[filterName][0].clear();

        this.decrementSelected();
      }
    },

    filterProducts(value, nameIndex) {
      if (!value) {
        value = [];
      } else if (!Array.isArray(value)) {
        value = [value];
      }
      // force filters on whenever filters change
      this.filterMeta.enableFilters = true;

      if (nameIndex === 'colours') {
        this.setFilters.colours = value;
      } else if (nameIndex === 'types') {
        this.setFilters.types = value;
      } else if (nameIndex === 'sizes') {
        this.setFilters.sizes = value;
      }

      this.emitFilterSet();
    },

    emitFilterSet() {
      if (this.filtersEnabled) {
        this.$emit('filter-set', this.setFilters);
      } else {
        this.$emit('filter-set', {types: [], colours: [], sizes: []});
      }
    },

    selectOptions(filterOptions) {
      return filterOptions.map(option => ({
        value: option.property,
        text: option.property,
      }));
    },

    showFirstTimeTooltip() {
      overlays.open('first-time-tooltip');
      Cookies.set('collection-filters-first-time-tooltip', true);
    },

    getTooltipTarget() {
      return this.$refs?.sort?.$el;
    },

    getDropdownValue(nameIndex) {
      return this.setFilters[nameIndex][0] ?? null;
    },

    getActiveFilterValue(nameIndex) {
      const filterData = this.filters[nameIndex];

      if (filterData.length) {
        return filterData[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-options-container {
  width: auto;
  white-space: nowrap;
  overflow-y: auto;
  max-height: 300px;
}

.filter-options-overlay {
  border-radius: $border-radius;
  position: absolute;
  width: calc(100% - 2em);
  max-height: calc(100% - 20%);
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.filter-option {
  border-top: 1px solid $transparent-grey !important;
  background-color: var(--tml-page-background-color);
  .filter-icon {
    height: 18px;
    top: 2px;
    right: 0;
    width: 2em;
  }
  .title {
    display: flex;
  }
  .filter-icon {
    background-color: var(--tml-page-background-color);
  }
}
.primary-text {
  color: var(--tml-text-color);
}

.colour-square {
  height: 25px;
  width: 35px;
  border-radius: $border-radius;
  border: 1px solid $transparent-grey;
}
</style>

<style lang="scss">
.filter-container .header {
  border-top: 1px solid $transparent-grey !important;
}

.filter-options-container .colour-square .color-square {
  border-radius: 3px;
}
</style>
